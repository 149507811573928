.projectContainer {
    min-height: 70vh;
    margin-bottom: 7px;
    text-align: center;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding: 110px 10px 0px 10px;
}

.projectHeader {
    font-size: 35px;
    font-weight: 500;
    padding-bottom: 15px;
}

/* <--Project Card--> */

.projectCardContainer {
    margin-top: 35px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.projectCard {
    width: 25rem;
    height: 32rem;
    border: 2px solid #2C7090;
    border-radius: 10px;
    margin: 20px;
    box-shadow: 0px 5px 4px 3px rgba(28, 137, 188, 0.137);
    transition: all 0.4s ease;
    padding: 0 10px ;
}

.projectCard:hover {
    transform: scale(1.05);
}

.image {
    height: 185px;
    margin-top: 10px;
    border-radius: 10px;
    border: 1px solid #2C7090;
}

.cardBody {
    margin: 12px 4px 0 4px;
    padding: 5px;
    height: 280px;
}

.content{
    height: 180px;
    padding: 2px 10px;
}

.cardTitle{
    color: #2C7090;
    padding-bottom: 12px;
    text-transform: capitalize;
}

.description{
    text-align: justify;
    line-height: 22px;
    font-size: 17px;
}

.projectLinkContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90px;
    padding: 5px;
}

.link{
    width: 85%;
    text-decoration: none;
    color: white;
    background-color: #2C7090;
    height: 55px;
    margin: 4px 0;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.link:hover{
    color: #05ABC4;
    transition: all 0.4s ease;
}