.container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.imgLogo {
    width: 130px;
    height: 130px;
    margin-bottom: 50px;
    border: 2px solid #2C7090;
    border-radius: 50%;
    padding: 10px;
}

.heading,
.headingName {
    padding-bottom: 15px;
    font-weight: 500;
}

.mainName {
    color: #2C7090 !important;
}

/* <--Home2--> */

.selfIntroduction {
    width: 50%;
    max-width: 1048px;
    margin: auto;
    padding: 10px;
}

.introHeader {
    text-align: center;
    margin-bottom: 40px;
}

.homeAboutBody {
    font-size: 20px;
    line-height: 30px;
    text-align: justify;
}

@media (max-width: 786px) {
    .selfIntroduction {
        width: 80%;
    }
}