.dataWrapper {
    height: 96vh;
    width: 100%;
}

.header {
    text-align: center;
    font-size: 40px;
    padding: 20px 0;
}

.notifyContainer {
    width: 80%;
    height: 80vh;
    border-radius: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow-y: none;
    padding: 25px;
}

.topContainer{
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.topContainer button{
    width: 150px;
    height: 40px;
    border: none;
    border-radius: 10px;
    background-color: red;
    color: white;
    font-size: 18px;
    margin-right: 10px;
    cursor: pointer;
}

.topContainer button:active{
    transform: scale(0.9);
}

.messageWrapper{
    background-color: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(12px) saturate(110%);
    height: 700px;
    width: 100%;
    border-radius: 20px;
    padding: 20px;
    overflow-y: auto;
}

/* <--Card Style--> */
.cardContainer{
    width: 95%;
    margin: 10px auto;
    min-height: 100px;
    border-radius: 16px;
    background-color: rgba(255, 255, 255, 0.25);
    padding: 12px;
    display: grid;
    grid: 
    'msg msg msg msg msg action'
    'info info info info info action';
    grid-gap: 5px;
}

.msgContainer{
    grid-area: msg;
    padding: 10px;
}

.msgContainer p{
    font-size: 18px;
}

.otherInfoContainer{
    grid-area: info;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    gap: 20px;
}

.otherInfoContainer small{
    font-size: 14px;
    text-transform: capitalize;
    padding: 5px;
}

.actionContainer{
    grid-area: action;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
}

.actionContainer button{
    width: 100px;
    height: 40px;
    border: none;
    border-radius: 10px;
    background-color: red;
    color: white;
    font-size: 18px;
    cursor: pointer;
}

.actionContainer button:active{
    transform: scale(0.9);
}