.loadingWrapper {
    width: 100%;
    height: 100vh;
    z-index: 1;
    position: absolute;
    top: 0;
    animation: fading 200ms 1 both;
}

.backgroundCover {
    width: 100%;
    height: 100vh;
    background-color: black;
    opacity: 0.5;
    animation: fading 200ms 1 both;
}

@keyframes fading {
    0% {
        transform: scale(0.75);
    }

    100% {
        transform: scale(1);
    }
}

.content {
    position: absolute;
    color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.timer{
    font-size: 24px;
    font-weight: 700;
}

.loader {
    width: 50px;
    height: 50px;
    border: 15px solid #fff;
    border-top-color: #2C7090;
    border-radius: 50%;
    animation: loading 1s ease-in-out infinite;
}

@keyframes loading {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}