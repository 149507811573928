.certificateContainer {
    min-height: 70vh;
    text-align: center;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 7px;
    padding: 110px 10px 0 10px;
}

.certificateCardContainer {
    margin-top: 35px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

/* <--Certificate Card--> */

.certificateCard {
    width: 18rem;
    height: 22rem;
    border: 2px solid #2C7090;
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
    transition: all 0.4s ease;
    box-shadow: 0px 5px 4px 3px rgba(28, 137, 188, 0.137);
}

.certificateCard:hover {
    transform: scale(1.05);
}

.image {
    width: 17rem;
    border-radius: 8px;
}

.cardBody {
    height: 118px;
    margin-top: 12px;
    padding: 5px;
}

.titleContainer{
    height: 75px;
}

.cardTitle {
    color: #2C7090 !important;
}

.issureContainer {
    height: 38px;
    font-size: 28px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    padding: 0 10px;
}