.loginContainer {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.header,
.formContainer,
.footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.header {
    height: 11vh;
    justify-content: end !important;
}

.header h1 {
    font-size: 40px;
    font-weight: 700;
}

.formContainer {
    height: 65vh;
}

.footer {
    border-top: 1px solid #ced4da;
    height: 12vh;
    position: fixed;
    top: 88vh;
    gap: 20px;
    font-size: 26px;
    text-align: center;
}

.loginForm {
    width: 500px;
    padding: 50px;
    border-radius: 35px;
}

.loginForm label {
    font-size: 22px;
    padding-left: 5px;
}

.loginForm input {
    width: 97%;
    height: 50px;
    border-radius: 15px;
    border: none;
    font-size: 20px;
    padding-left: 10px;
    outline: none;
}

.loginForm input:focus {
    outline: 2px solid #2C7090;
}

.formData {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    padding: 10px;
    margin: 10px;
}

.loginBtn {
    width: 100%;
    height: 50px;
    font-size: 18px;
    border-radius: 20px;
    border: none;
    background-color: #2C7090;
    color: white;
    cursor: pointer;
    transition: all 0.4s ease;
}

.loginBtn:hover {
    color: #05ABC4;
    opacity: 0.9;
}

.passwordContainer{
    position: relative;
}

.hideShow{
    position: absolute;
    top: 57px;
    left: 85%;
    font-size: 28px;
    cursor: pointer;
    color: #2C7090 !important;
    transition: all 0.4s ease;
}

.hideShow:hover{
    color: #05ABC4 !important;
}

@media (max-width: 625px) {
    .loginForm{
        width: 420px;
        padding: 20px;
    }
}

@media (max-width: 450px) {
    .loginForm{
        width: 345px;
        padding: 10px;
    }
}