.backgroundWrapper {
    width: 100%;
    height: 100vh;
    z-index: 1;
    position: absolute !important;
    top: 0px !important;
    left: 0px !important;
    display: none;
}

.background{
    background-color: black;
    opacity: 0.5;
    width: 100%;
    height: 100vh;
}

.container {
    height: 300px;
    width: 700px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 24px;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-top: 8px solid red;
}

.header{
    margin: 10px;
    padding: 10px;
    text-align: center;
    text-transform: capitalize;
}

.message{
    margin: 10px;
    padding: 10px;
    font-size: 20px;
    line-height: 30px;
    min-height: 100px;
}

.action{
    margin: 10px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.action button{
    width: 50%;
    height: 50px;
    margin: 0 5px;
    font-size: 18px;
    border-radius: 20px;
    cursor: pointer;
    border: none;
}

.action button:active{
    transform: scale(0.9);
}

.cancle{
    background-color: rgb(65, 172, 65);
}

.confirm{
    background-color: red;
    color: white;
}

.togglewrapper {
    display: block !important;
    animation: wrapper 200ms 1 both;
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

@keyframes wrapper {
    0% {
        transform: scale(0.75);
    }

    100% {
        transform: scale(1);
    }
}