.sideNavContainer {
    width: 300px;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(12px) saturate(110%);
}

.adminContainer {
    border-bottom: 1px solid white;
    width: 93%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    padding: 16px 10px;
}

.adminContainer h5 {
    font-size: 24px;
    color: rgb(36, 34, 34);
}

.logo {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: 1px solid white;
    padding: 2px;
}

.sideNavItemContainer {
    width: 100%;
    height: 550px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sideNavItem {
    width: 80%;
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin: 10px 0;
    padding: 0 10px;
    border-radius: 20px;
    font-size: 20px;
    text-decoration: none;
    color: black;
    transition: all 0.4s ease-in-out;
}

.sideNavItem:hover {
    background-color: #2C7090;
    color: white;
}

.logoutContainer {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logoutBtn {
    width: 80%;
    height: 50px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: 20px;
    border: none;
    background-color: #2C7090;
    color: white;
    cursor: pointer;
    transition: all 0.4s ease-in-out;
}

.logoutBtn:active{
    transform: scale(0.9);
}