.dataWrapper {
    width: 100%;
    height: 100vh;
}

.header {
    text-align: center;
    font-size: 40px;
    padding: 20px 0;
}

.dataContainer {
    width: 80%;
    height: 90vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
}

.addBtn,
.editBtn,
.deleteBtn {
    width: 250px;
    height: 50px;
    border: none;
    border-radius: 20px;
    font-size: 18px;
    color: white;
    cursor: pointer;
    margin: 10px 15px;
}

.addBtn,
.editBtn,
.deleteBtn:active {
    transform: scale(0.9);
}

.addBtn {
    background-color: #2C7090;
}

.editBtn {
    background-color: #05ABC4;
}

.deleteBtn {
    background-color: red;
}


.resume {
    height: 80vh;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.file {
    display: flex;
    justify-content: center;
}

/* <--toggleWrapper--> */

.backgroundWrapper {
    width: 100%;
    height: 100vh;
    z-index: 1;
    position: absolute !important;
    top: 0px !important;
    left: 0px !important;
    display: none;
}

.background {
    background-color: black;
    opacity: 0.5;
    width: 100%;
    height: 100vh;
}

.container {
    height: 90vh;
    width: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 24px;
}

.topContainer {
    height: 75px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.bottomContainer {
    background-color: rgba(255, 255, 255, 0.5);
    min-height: 85vh;
    border-radius: 20px;
}

.btnClose {
    width: 50px;
    height: 50px;
    font-size: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.5);
    color: white;
    cursor: pointer;
}

.btnClose:active {
    transform: scale(0.9);
}

.togglewrapper {
    display: block !important;
    animation: wrapper 200ms 1 both;
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

@keyframes wrapper {
    0% {
        transform: scale(0.75);
    }

    100% {
        transform: scale(1);
    }
}

.form {
    width: 100%;
    height: 20vh;
    display: flex;
    flex-direction: column;
}

.formData {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    flex-direction: column;
    gap: 15px;
}

.formData input {
    width: 95%;
    border-radius: 15px;
    border: none;
    background-color: rgba(255, 255, 255, 0.3);
    font-size: 18px;
    outline: none;
    min-height: 45px;
    height: 45px;
}

.formData input:focus {
    outline: 2px solid #2C7090;
}

.btnSubmit {
    height: 45px;
    width: 65%;
    background-color: #2C7090;
    color: white;
    font-size: 22px;
    border-radius: 20px;
    border: none;
    transition: all 0.4s ease-in-out;
    cursor: pointer;
    margin: auto;
}

.btnSubmit:active {
    transform: scale(0.9);
}

::-webkit-file-upload-button {
    color: white;
    background-color: #2C7090;
    width: 200px;
    height: 45px;
    border: none;
    text-transform: capitalize;
    cursor: pointer;
}

.prevResume {
    height: 90vh;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.prevFile {
    height: 65vh;
    display: flex;
    justify-content: center;
}