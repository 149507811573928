.aboutContainer {
    font-size: 24px;
}

.aboutCard {
    padding-top: 200px;
    width: 50%;
    max-width: 786px;
    margin: auto;
}

.aboutHeader {
    text-align: center;
    margin-bottom: 50px;
    font-size: 36px;
    font-weight: 500;
}

.para {
    margin-top: 20px;
    line-height: 32px;
}

.aboutActivity {
    text-align: left;
    list-style: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding: 5px 0 5px 35px;
}

/* <--Tech Stack--> */

.techStackContainer {
    text-align: center;
    margin: 50px 0;
}

.stackHeader {
    font-size: 35px;
    font-weight: 500;
}

.stackContainer {
    width: 80%;
    margin: auto;
    max-width: 1048px;
    margin-top: 22px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.techIcons {
    font-size: 4.5em;
    width: 175px;
    height: 175px;
    margin: 15px;
    padding: 10px;
    opacity: 0.93;
    border: 1.7px solid #2C7090;
    vertical-align: middle;
    text-align: center;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 4px 5px 4px 3px rgba(28, 137, 188, 0.137);
    overflow: hidden;
    transition: all 0.4s ease 0s;
}

.techIcons:hover {
    transform: scale(1.2);
}

/* .techIcons:active{
    transform: rotatey(180deg);
} */

/* <--Tools Stack--> */

.toolsHeader{
    text-align: center;
    font-weight: 500;
    font-size: 35px;
}

/* <--Github--> */

.gitHubContainer{
    width: 80%;
    margin: auto;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.gitHubContainer h1{
    font-size: 35px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 25px;
}